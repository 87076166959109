@import 'fonts';
@import 'variables';
@import 'bootstrap';

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.ff-montserrat {
  font-family: $font-secondary;
}

.width-720 {
  width: 720px;
  border-bottom-width: 2px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.btn {
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: .5rem;
  }

  &.btn-save {
    width: 70%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.textarea-condizioni {
  padding-left: 2.5rem;

  .textarea {
    font-size: .75rem;
    min-height: 100px;
    border: 1px solid $lightgray2;
    background-color: $lightgray1;
    max-height: 100px;
    padding: .5rem;

    h2,
    h3,
    h4 {
      font-family: $font-secondary;
      font-weight: bold;
      margin-bottom: .3rem;
    }
  
    h1 {
      font-size: .95rem;
    }

    h2 {
      font-size: .9rem;
    }

    h3 {
      font-size: .8rem;
    }
  }
}