// Open Sans
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-display: swap;
  src: url('fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-display: swap;
  src: url('fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('fonts/OpenSans-BoldItalic.ttf');
}

// Montserrat
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-display: swap;
  src: url('fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  src: url('fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/Montserrat-Italic.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('fonts/Montserrat-BoldItalic.ttf');
}