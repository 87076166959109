// Fonts
$font-family-base: 'Open Sans', sans-serif;
$font-secondary: 'Montserrat', sans-serif;

// Colors
$white: white;
$blue: #1951a0;
$lightblue: #95b3ff;
$yellow: #f3900e;
$lightgray1: #fafafa;
$lightgray2: #e5e5e5;

// Overrides
$body-bg: $lightgray1;
$small-font-size: .8rem;

$card-bg: $white;
$card-border-radius: 3px;
$card-border-color: $lightgray2;

$input-padding-y: .6rem;
$input-border-radius: 3px;

$form-label-color: $lightblue;
$form-label-font-weight: bold;
$form-label-font-size: .8rem;
$form-label-margin-bottom: .2rem;

$btn-padding-x: 3rem;
$btn-padding-y: .7rem;
$btn-font-family: $font-secondary;
$btn-font-weight: bold;

// BS Disable
$enable-dark-mode: false;
$enable-caret: false;